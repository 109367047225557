//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import omit from 'lodash/omit';
import LazyHydrate from 'vue-lazy-hydration';
const BASE_CLASS = 'flex-layout';

const NATIVE_COMPONENTS = {
  a: 'a',
  p: 'p'
};

export default {
  name: 'SFlexLayout',
  components: {
    SSection: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SSection'),
    SLayoutRow: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SLayoutRow'),
    SSectionColumn: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SSectionColumn'),
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner'),
    LazyHydrate
  },
  props: {
    hero: {
      type: Object,
      required: false,
      default: null
    },
    rows: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    onSetup: {
      type: Function,
      default: () => {}
    },
    isLoading: {
      type: [Boolean, Object],
      default: false
    }
  },
  setup(props) {
    if (props.onSetup) props.onSetup();

    const computedRows = computed(() => (Array.isArray(props.rows) ? props.rows : props.rows.value));
    const computedIsLoading = computed(() =>
      props.isLoading.value !== undefined ? props.isLoading.value === true : props.isLoading
    );
    const containerClasses = computed(() => [BASE_CLASS]);

    return {
      computedRows,
      computedIsLoading,
      containerClasses,
      SUPPORTED_COMPONENTS: {
        ...NATIVE_COMPONENTS,
        SPicture: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SPicture'),
        SButton: () =>
          import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
        SFaq: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SFaq'),
        SHeadline: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SHeadline'),
        SList: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SList'),
        STile: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/STile'),
        SContent: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SContent'),
        SVideoEmbed: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SVideoEmbed'),
        SHero: () =>
          import(/* webpackChunkName: "chunk-ui-molecules-heroes" */ '@/components/UI/molecules/heroes/SHero'),
        SHtml: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SHtml'),
        SEventGrid: () => import(/* webpackChunkName: "chunk-page-product" */ '@/components/Event/SEventGrid'),
        STabbedEventGrid: () =>
          import(/* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/STabbedEventGrid'),
        SFilteredEventGrid: () =>
          import(/* webpackChunkName: "chunk-page-category" */ '@/components/ProductListing/SFilteredEventGrid'),
        SNewsLetterBanner: () =>
          import(/* webpackChunkName: "chunk-ui-organisms" */ '@/components/UI/organisms/newsletter/SNewsLetterBanner'),
        SLogoRow: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SLogoRow'),
        SSeparator: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SSeparator'),
        SAnchor: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SAnchor'),
        WebpImage: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/WebpImage'),
        NSocialLinks: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/SocialLinks'),
        SHeaderSlogan: () => import(/* webpackChunkName: "chunk-header" */ '~/components/Header/SHeaderSlogan'),
        SCarousel: () =>
          import(
            /* webpackChunkName: "chunk-ui-molecules-carousels" */ '@/components/UI/molecules/carousels/SCarousel'
          ),
        SFounder: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SFounder'),
        STeamEvent: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/STeamEvent'),
        SReviews: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SReviews'),
        SVoucherHeader: () =>
          import(/* webpackChunkName: "chunk-page-voucher" */ '@/components/Pages/Voucher/children/SVoucherHeader'),
        NuxtTransformImage: () =>
          import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage'),
        SGeneralBanner: () =>
          import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SGeneralBanner'),
        SBannerContent: () =>
          import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/molecules/SBannerContent')
      },
      omit
    };
  }
};
